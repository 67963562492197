.wolWrapper {
  position: relative;
}

.wolWrapper .run {
  top: 2px;
  left: 40px;
  position: absolute;
}

header h6.MuiTypography-h6 {
  font-weight: 1000;

}

[type="file"] {
    height: 0;
    overflow: hidden;
    width: 0;
}
